<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-body">
            <!-- TODO: Komponen Filter Tabel -->
            <m-filter class="mb-4" @search="search" @fetchXlsx="fetchXlsx" @add="add" />
            <div class="table-responsive  ">
              <md-table
                :columns="columns"
                :data-source="data"
                size="small"
                row-key="id"
                :meta="meta"
                @change="handleTableChange"
                :loading="isFetching"
              >
                <template #status="{ record }">
                  <a-tag v-if="record.active" color="#108ee9">{{ record.status_text }}</a-tag>
                  <a-tag v-else color="grey">{{ record.status_text }}</a-tag>
                </template>
                <template #areas="{ record }">
                  <div style="margin: auto; max-width: 15.625rem; min-width: 15.625rem;">
                    {{ record.areas }}
                  </div>
                </template>
                <template #action="{record}">
                  <span>
                    <a-tooltip title="Lihat Grup Mapping">
                      <a @click="lihatGroup(record)" class="btn btn-sm btn-light">
                        <small><i class="fe fe-eye" /> </small>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Ubah Grup Mapping">
                      <a @click="editGroup(record)" class="btn btn-sm btn-light">
                        <small>
                          <i class="fe fe-edit" />
                        </small>
                      </a>
                    </a-tooltip>
                    <a-tooltip title="Hapus Grup Mapping">
                      <a @click="hapusGroup(record)" class="btn btn-sm btn-light">
                        <small>
                          <i class="fe fe-trash" />
                        </small>
                      </a>
                    </a-tooltip>
                  </span>
                </template>
              </md-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal
      v-if="showModal"
      :show="showModal"
      @cancel="showModal = false"
      @refresh="refresh"
      v-model:state="stateModal"
    ></modal>
  </div>
</template>

<script>
import MFilter from './components/forms/filter' // local component
import apiClient from '@/services/axios'

import { Modal, message } from 'ant-design-vue'
import { ref, onMounted, reactive, toRefs } from 'vue'
const acolumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    align: 'center',
  },
  {
    title: 'Nama',
    dataIndex: 'name',
    align: 'center',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    align: 'center',
  },
  {
    title: 'Atasan',
    dataIndex: 'atasan',
    align: 'center',
  },
  {
    title: 'Area',
    dataIndex: 'areas',
    align: 'center',
    slots: { customRender: 'areas' },
  },

  {
    title: 'Status',
    slots: { customRender: 'status' },
    align: 'center',
  },

  {
    title: 'Action',
    slots: { customRender: 'action' },
    align: 'center',
  },
]
import qs from 'qs'

import modal from './modalPost'

import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

export default {
  name: 'VbPemetaanGrupArea',
  components: {
    MFilter,
    modal,
  },
  setup(props, { emit }) {
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const columns = ref([])
    const data = ref([])
    const role = ref([])

    columns.value = acolumns

    const q = ref('')

    const errorMessage = ref(null)

    const state = reactive({
      selectedRowKeys: [],
      loading: false,
      isFetching: false,
      showModal: false,
    })

    const search = filters => {
      q.value = filters.q
      role.value = filters.roles
      fetchData()
    }

    const params = ref({})
    const fetchXlsx = () => {
      apiClient
        .get('/api/group-roles', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
              .map(({ dataIndex: key, title }) => ({ key, title })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params, ok: 'hmm' }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          if (response === undefined) {
            errorMessage.value = `Kode error 500, No response from server`
            return
          }
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Pemetaan-Grup-Area_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
    }

    const fetchData = () => {
      const _params = {
        page: meta.value.currentPage,
        'per-page': meta.value.perPage,
        q: q.value,
        status: status.value,
        role: role.value,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/group-roles', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          data.value = items
          meta.value = _meta || { pageCount: 0, totalCount: 0 }
          pageCount.value = meta.value.pageCount
          totalCount.value = meta.value.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    onMounted(() => {
      fetchData()
    })
    const handleTableChange = (pag, filters, sorter) => {
      meta.value.currentPage = pag.current
      meta.value.perPage = pag.pageSize
      fetchData()
    }
    const stateModal = reactive({
      title: null,
    })
    const add = () => {
      stateModal.title = 'Tambah Group Mapping'
      state.showModal = true
      stateModal.readOnly = false
      stateModal.record = {}
    }
    const lihatGroup = record => {
      stateModal.title = 'Lihat Group Mapping'
      state.showModal = true
      stateModal.readOnly = true
      stateModal.record = cloneDeep(record)
    }
    const editGroup = record => {
      stateModal.title = 'Ubah Group Mapping'
      state.showModal = true
      stateModal.readOnly = false
      stateModal.record = cloneDeep(record)
    }
    const hapusGroup = ({ id }) => {
      Modal.confirm({
        title: 'Konfirmasi hapus',
        content: 'Apakah anda ingin Hapus lokasi ?',
        onOk() {
          apiClient
            .delete(`/api/group-roles/${id}`)
            .then(response => {
              fetchData()
              message.success('Berhasil dihapus')
            })
            .catch(e => message.error('Gagal menghapus!'))
        },
        onCancel() {},
      })
    }
    const refresh = () => {
      fetchData()
    }
    return {
      search,
      data,
      meta,
      handleTableChange,
      state,
      ...toRefs(state),
      columns,
      fetchXlsx,
      add,
      stateModal,
      lihatGroup,
      editGroup,
      hapusGroup,
      refresh,
      errorMessage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
